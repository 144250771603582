<template>
	<div class="container">
		<a-row type="flex" justify="center">
			<a-steps class="steps" :current="currentStep">
				<a-step title="Proprietário" />
				<a-step title="Restaurante" />
				<a-step title="Apresentação" />
				<a-step title="Contrato" />
			</a-steps>
		</a-row>
		<a-row type="flex" justify="center">
			<p v-if="currentStep === 0" class="step-header">
				Forneça as suas informações como proprietário
			</p>
			<p v-if="currentStep === 1" class="step-header">
				Preencha com as informações do seu restaurante
			</p>
			<p v-if="currentStep === 2" class="step-header">
				Forneça as informações para o aplicativo
			</p>
		</a-row>
		<a-row class="form-container">
			<router-view />
		</a-row>
	</div>
</template>

<script>
const Steps = Object.freeze({
	'partner-info': 0,
	'company-info': 1,
	'merchant-info': 2,
	agreement: 3,
})

export default {
	name: 'RegisterPage',
	data() {
		return {
			currentRoute: 'partner-info',
		}
	},
	computed: {
		currentStep: function () {
			return Steps[this.currentRoute]
		},
	},
	watch: {
		$route: function () {
			this.getCurrentRoute()
		},
	},
	mounted() {
		this.getCurrentRoute()
	},
	methods: {
		getCurrentRoute() {
			this.currentRoute = this.$route.path
				.split('/')
				.filter((route) => route !== '')
				.pop()
		},
	},
}
</script>

<style lang="less" scoped>
.container {
	padding-top: 32px;
	display: flex;
	justify-content: center;
	flex-direction: column;

	.steps {
		max-width: 750px;
		margin: 32px 0;
	}

	.step-header {
		font-size: 20px;
		font-weight: bold;
		margin: 32px 0 40px 0;
		color: #3574b6;
	}

	.form-container {
		margin: 0px 0 64px 0;
	}
}
</style>
